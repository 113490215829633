@import 'colors';

// Common Variables
$base-unit: 16; // 16px
$paragraph-spacer: 30;
$spacer-vertical-rhythm: $base-unit;

$is-amp: false !default;

// Container
$container-padding: $base-unit; // 16px
$container-max-width: 1280;
$container-mobile-gutter: 20;
$container-home-content-width-tablet: 696;
$container-weather-large: 1388;
$container-weather-tablet-width: 640;
$container-weather-small-desktop-width: 1140;

// Article
$article-secondary-width: 300;
$article-gutter: 36;

$column-width: 64;
$columns-map: (
  xl: 12, // 1280px
  l: 12, // 1024px
  m: 8, // 768px
  s: 4, // 375px
  xs: 4 // 375px and under
);

// Fonts
$font-serif: georgia, times, serif;
$font-sans-serif: helvetica, arial, sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-roboto-mono: 'Roboto Mono', sans-serif;
$font-arthouse: 'Arthouse', sans-serif;
$font-arthouse-bold: 'Arthouse Bold', sans-serif;
$font-georgia: 'Georgia', serif;

// Type sizes and weights
$body-text-size: 17;
$body-line-height: 27;
$body-text-size-mobile: 16;
$body-line-height-mobile: 27;
$cap-size: 18;
$font-weight-bold: 700;
$share-button-size: 30;

// Transitions
$transition-opacity: opacity 0.3s;
$transition-background: background 0.3s;
$transition-link: color 0.3s;

// Header and Footer fixed desktop widths
$l-fixed: 1210;
$xl-fixed: 1369;

// z-index hierarchy
$indices: shareButtons,
  sliderControl,
  videoThumbnail,
  recircInPosts,
  sponsoredNotice,
  bottomRecircCard3,
  bottomRecircCard2,
  bottomRecircCard1,
  bottomRecircCard0,
  bottomRecircOverlay,
  bottomRecircTopLayer,
  bottomRecircNextSlide,
  siteHeader,
  comments;

// Borders
$border-article-list: 1px solid $color-gray-08;
$border-article-list-border-low-opacity: 1px solid rgba($color-gray-08, 0.3);
$border-article-list-border-dark-mode: 1px solid $color-gray-alpha-03;
$border-weather-page-module: 1px solid $color-gray-alpha-08;

// News Module
$module-border: 1px solid $color-gray-08;
$module-gutter: 36px;

// Weather iframe embed
$weather-iframe-alerts: #be2a2a;
$weather-iframe-current: #232323;
$weather-iframe-future: #4a4a4a;
$weather-frame-border: #c7c7c7;
$weather-sponsor-background: #5e5e5e;
