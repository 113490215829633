.site-footer {
  background-color: $color-white;
  border-top: 1px $color-gray-06 solid;
  font-family: $font-arthouse;
  lost-flex-container: row;
  lost-utility: clearfix;
  padding: toRem(20);

  @media (media(s-min)) { // 375+
    padding: toRem(25 35);
  }

  @media (media(l-min)) { // 1280+
    padding: toRem(25 40);
  }

  &__wrapper {
    lost-flex-container: row;
    lost-utility: clearfix;
    width: 100%;

    @media (media(l-min)) {
      margin: 0 auto;
      width: toRem($l-fixed);
    }

    @media (media(l-min)) {
      width: toRem($xl-fixed);
    }
  }

  a {
    text-decoration: none;
  }

  &__identity {
    align-items: center;
    border-bottom: 1px $color-gray-06 solid;
    display: flex;
    justify-content: space-between;
    lost-column: 8/8 0 0;
    margin-bottom: toRem(16);
    padding-bottom: toRem(20);

    .station-white-label & {
      border-bottom: none;
      display: block;
    }

    @media (media(l-min)) { // 1280+
      border-bottom: 0;
      display: block;
      lost-column: 1.4/8 0 0;
      margin-bottom: 0;
      padding-bottom: 0;

      .station-white-label & {
        lost-column: 1/3;
      }
    }

    img {
      max-height: toRem(40);
    }
  }

  &__social-list {
    display: flex;

    a {
      color: $color-gray-38;
    }

    &-item {

      a {
        background: $color-gray-17;
        border-radius: 50%;
        display: block;
        font-size: 16px;
        height: toRem(30);
        line-height: 30px;
        margin-right: 12px;
        text-align: center;
        width: toRem(30);

        @media (media(l-min)) { // 1280+
          font-size: 20px;
          height: toRem(36);
          line-height: 36px;
          width: toRem(36);
        }
      }
    }
  }

  &__logo {
    display: block;
    max-width: toRem(142);
    width: 100%;

    @media (media(l-min)) { // 1280+
      margin: 0 0 32px;
    }

    .station-white-label & {
      margin: 0 0 32px;
    }
  }

  &__menu-primary {
    display: flex;
    flex-flow: column wrap;
    lost-column: 8/8 0 0;
    max-width: 55%;

    @media (media(sm-min)) { // 480+
      lost-column: 3.3/8 0 0;
      max-height: calc(27px * 14);
    }

    @media (media(m-min)) { // 768+
      lost-column: 3/8 0 0;
      max-height: calc(27px * 6);
    }

    @media (media(l-min)) { // 1280+
      lost-column: 2.6/8 0 0;
    }

    li {
      margin-bottom: toRem(16);
      padding-right: 13px;

      @media (media(sm-min)) { // 480+
        width: 70%;
      }

      @media (media(m-min)) { // 768+
        margin-bottom: 0;
      }
    }

    a {
      color: $color-gray-alpha-17;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 1.3;
      text-transform: uppercase;

      @media (media(sm-min)) { // 480+
        font-size: 12px;
        line-height: 27px;
      }

      @media (media(ml-min)) { // 1140+
        font-size: 12px;
      }
    }
  }

  &__menu-secondary {
    max-width: 45%;

    @media (media(sm-min)) { // 480+
      lost-column: 3.3/8 1 0;

      .station-white-label & {
        display: block;
        margin: 0;
      }
    }

    @media (media(m-min)) { // 768+
      lost-column: 1.8/8 1 0;

      .station-white-label & {
        display: block;
        margin: 0;
      }
    }

    @media (media(l-min)) { // 1280+
      display: flex;
      flex-flow: column wrap;
      lost-column: 2.3/8 1 0;
    }

    li {
      margin-bottom: toRem(16);

      @media (media(sm-min)) {
        margin-bottom: 0;
      }

      @media (media(l-min)) {
        padding-left: 13px;
      }
    }

    a,
    .ot-sdk-show-settings {
      color: $color-gray-alpha-20;
      cursor: pointer;
      font-size: 10px;
      line-height: 1.2;

      @media (media(m-min)) {
        line-height: 18px;
      }
    }

    // stylelint-disable selector-max-specificity
    #ot-sdk-btn.ot-sdk-show-settings {
      align-items: center;
      border: none;
      color: $color-gray-alpha-20;
      display: flex;
      font-size: 10px;
      line-height: 18px;
      padding: 0;
      transition: none;

      &:hover {
        background: none;
        color: $color-gray-alpha-20;
      }

      &.toggle::before {
        background-image: url('../../images/icons/icon-privacy-toggle.svg');
        background-repeat: no-repeat;
        background-size: auto 10px;
        content: '';
        height: 10px;
        margin-right: 2px;
        width: 22px;
      }
      
      .single-video &,
      .post-type-archive-video & {
        color: $color-white;
      }
    }
    // stylelint-enable selector-max-specificity
  }

  &__corporate {
    color: $color-gray-16;
    display: block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.16px;
    line-height: 32px;
    margin-top: toRem(40);
    width: 100%;

    @media (media(l-min)) { // 1280+
      margin-top: toRem(100);
    }

    img {
      display: block;
    }
  }
}
