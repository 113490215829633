/* Colors */
$color-black: #000;
$black: $color-black;
$color-white: #fff;
$white: $color-white;

// Alphas grays
$color-gray-alpha-01: rgba($white, 0.28);
$color-gray-alpha-02: rgba(216, 216, 216, 0.11);
$color-gray-alpha-03: rgba(216, 216, 216, 0.2);
$color-gray-alpha-04: rgba(255, 255, 255, 0.08);
$color-gray-alpha-05: rgba(255, 255, 255, 0.1);
$color-gray-alpha-06: rgba(255, 255, 255, 0.16);
$color-gray-alpha-07: rgba(255, 255, 255, 0.2);
$color-gray-alpha-08: rgba(255, 255, 255, 0.3);
$color-gray-alpha-09: rgba(255, 255, 255, 0.4);
$color-gray-alpha-10: rgba(#0d0d0d, 0.9);
$color-gray-alpha-11: rgba($color-black, 0.4);
$color-gray-alpha-12: rgba(0, 0, 0, 0.5);
$color-gray-alpha-13: rgba(0, 0, 0, 0.55);
$color-gray-alpha-14: rgba(0, 0, 0, 0.6);
$color-gray-alpha-15: rgba(0, 0, 0, 0.7);
$color-gray-alpha-16: rgba($color-black, 0.9);
$color-gray-alpha-17: rgba(0, 0, 0, 0.9);
$color-gray-alpha-18: rgba(0, 0, 0, 0.8);
$color-gray-alpha-19: rgba(0, 0, 0, 0);
$color-gray-alpha-20: rgba(0, 0, 0, 1);

// Alpha greens
$color-green-alpha-01: rgba(184, 233, 134, 0.13);

// Blues
$color-blue-01: #edf3fb;
$color-blue-02: #6ab1ed;
$color-blue-03: #439fd8;
$color-blue-04: #419fef;
$color-blue-05: #4084dd;
$color-blue-06: #1e6ed8;
$color-blue-07: #1b70d8;
$color-blue-08: #1f6ed7;
$color-blue-09: #296de8;
$color-blue-10: #246be5;
$color-blue-11: #134595;
$color-blue-12: #144aa8;
$color-blue-13: #133484;
$color-blue-14: #0e3184;
$color-blue-15: #083098;
$color-blue-16: #b0bec51a;
$color-blue-17: #05aaf51a;
$color-blue-18: #192d5b;
$color-blue-19: #0f2e62;
$color-blue-20: #102e63;
$color-blue-21: #0c2653;

// Grays
$color-gray-01: #f2f2f2;
$color-gray-02: #f4f5f6;
$color-gray-03: #ececec;
$color-gray-04: #e7e7e7;
$color-gray-05: #f9f9f9;
$color-gray-06: #d8d8d8;
$color-gray-07: #ddecff;
$color-gray-08: #e0dfdc;
$color-gray-09: #e3e3e3;
$color-gray-10: #fafafa;
$color-gray-11: #c8c8c8;
$color-gray-12: #c9c9c9;
$color-gray-13: #bfbfbe;
$color-gray-14: #ddd;
$color-gray-15: #adadad;
$color-gray-16: #c2c2c2;
$color-gray-17: #eaeaea;
$color-gray-18: #b9b9b9;
$color-gray-19: #e9e9e9;
$color-gray-20: #a8a8a8;
$color-gray-21: #8a8a8a;
$color-gray-22: #666;
$color-gray-23: #6b6b6b;
$color-gray-24: #6f6f6f;
$color-gray-25: #858585;
$color-gray-26: #767676;
$color-gray-27: #777;
$color-gray-28: #a5a5a4;
$color-gray-29: #999;
$color-gray-30: #5d5d5d;
$color-gray-31: #616161;
$color-gray-32: #64686d;
$color-gray-33: #707173;
$color-gray-34: #4b4b4b;
$color-gray-35: #444;
$color-gray-36: #5e5e5e;
$color-gray-37: #303030;
$color-gray-38: #171717;
$color-gray-39: #0c0c0c;
$color-gray-40: #1a1a1a;
$color-gray-41: #090a0b;
$color-gray-42: #0d0d0d;
$color-gray-43: #2d2d2d;
$color-gray-44: #3a3b3c;
$color-gray-45: #31363d;
$color-gray-46: #323232;
$color-gray-47: #1d1d1d;
$color-gray-48: #181a1e;
$color-gray-49: #191e23;
$color-gray-50: #24282d;
$color-gray-51: #222;
$color-gray-52: #252f58;

// Greens
$color-green-01: #7ed321;
$color-green-02: #0db04b;
$color-green-03: #29a06a;
$color-green-04: #1d974b;
$color-green-05: #0a8539;

// Reds
$color-red-01: #ef5350;
$color-red-02: #854343;
$color-red-03: #f32a43;
$color-red-04: #f0021f;
$color-red-05: #df2f2e;
$color-red-06: #d81817;
$color-red-07: #d0021b;
$color-red-08: #b11817;
$color-red-09: #bd0e00;
$color-red-10: #ff33331a;

// Purples
$color-purple-01: #7609ad;

// Yellows and oranges
$color-yellow-01: #fdd240;
$color-yellow-02: #ffca16;
$color-yellow-03: #fccc12;
$color-yellow-04: #fcb813; 
$color-orange-01: #ff5f00;
